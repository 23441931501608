var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select_items_filter_wrapper", attrs: { role: "group" } },
    [
      _vm.withLabel
        ? _c("label", {
            staticClass: "d-block",
            domProps: { innerHTML: _vm._s(_vm.label) },
          })
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                md: `${
                  _vm.canDoAdd
                    ? _vm.locations.length > 0
                      ? 4
                      : 8
                    : _vm.locations.length > 0
                    ? 6
                    : 12
                }`,
                lg: `${
                  _vm.canDoAdd
                    ? _vm.locations.length > 0
                      ? 4
                      : 8
                    : _vm.locations.length > 0
                    ? 6
                    : 12
                }`,
              },
            },
            [
              _c("v-select", {
                staticClass: "mb-2",
                style: `${
                  !_vm.isValid
                    ? "border: 1px solid #B11548; border-radius: 4px;"
                    : ""
                }`,
                attrs: {
                  placeholder: _vm.placeholder,
                  options: _vm.clients,
                  disabled: _vm.disabled,
                  label: "name",
                },
                on: {
                  "option:selected": _vm.emitSelectedClient,
                  input: _vm.onInput,
                },
                scopedSlots: _vm._u([
                  {
                    key: "no-options",
                    fn: function ({ search, searching }) {
                      return [
                        searching
                          ? [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1, "No results for")) +
                                  " "
                              ),
                              _c("em", [_vm._v(_vm._s(search))]),
                              _vm._v(
                                ". " +
                                  _vm._s(_vm.FormMSG(2, "You can add it")) +
                                  ".\n\t\t\t\t\t"
                              ),
                            ]
                          : _c("em", { staticStyle: { opacity: "0.5" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(
                                    3,
                                    "Start typing to search for a supplier"
                                  )
                                ) + "."
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selected_client,
                  callback: function ($$v) {
                    _vm.selected_client = $$v
                  },
                  expression: "selected_client",
                },
              }),
            ],
            1
          ),
          _vm.locations.length > 0
            ? _c(
                "b-col",
                {
                  attrs: {
                    md: `${_vm.canDoAdd ? 4 : 6}`,
                    lg: `${_vm.canDoAdd ? 4 : 6}`,
                  },
                },
                [
                  _c("v-select", {
                    class: _vm.$screen.width < 992 ? "mb-2" : "",
                    attrs: {
                      placeholder: _vm.locationPlaceholder,
                      options: _vm.locations,
                      disabled: _vm.disabled,
                      label: "setName",
                    },
                    on: {
                      "option:selected": _vm.onSelectedLocation,
                      input: _vm.onDeleteLocation,
                    },
                    model: {
                      value: _vm.locationSelected,
                      callback: function ($$v) {
                        _vm.locationSelected = $$v
                      },
                      expression: "locationSelected",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.canDoAdd
            ? _c("b-col", { attrs: { md: "4", lg: "4" } }, [
                _c(
                  "div",
                  {
                    staticClass: "supplier_actions_btns",
                    class: { has_two_btn: _vm.isCanModify },
                  },
                  [
                    _vm.isCanModify
                      ? _c(
                          "b-button",
                          {
                            staticClass: "py-2",
                            on: { click: _vm.openModalEdit },
                          },
                          [_c("i", { staticClass: "fa fa-edit" })]
                        )
                      : _vm._e(),
                    _c("b-button", {
                      staticClass: "py-2",
                      attrs: { block: "", variant: "outline-primary" },
                      domProps: {
                        innerHTML: _vm._s(
                          `${!_vm.isCanModify ? _vm.addLabel : _vm.updateLabel}`
                        ),
                      },
                      on: {
                        click: function ($event) {
                          ;`${
                            !_vm.isCanModify
                              ? _vm.openModalNew()
                              : _vm.openModalEdit()
                          }`
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("ClientModal", {
        attrs: {
          editable: _vm.is_modal_edit,
          "focus-client": _vm.data_to_edit,
        },
        on: {
          "client:delete": _vm.handleDeleteClient,
          "client:create": _vm.createClient,
          "client:update": _vm.editClient,
          "client-location:removed": _vm.onLocationRemoved,
        },
        model: {
          value: _vm.open_modal,
          callback: function ($$v) {
            _vm.open_modal = $$v
          },
          expression: "open_modal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }