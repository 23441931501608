<template>
	<!--
    bg-variant="secondary"
    text-variant="white"
    title="Fill supplier's address"
  -->
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isOpen"
		:title="titleModal"
		size="xl"
		ok-variant="success"
		:ok-title="okTitle"
		:cancel-title="FormMSG(2, 'Cancel')"
		@ok="handleSubmitModal"
		@cancel="handleCloseModal"
		@hidden="handleCloseModal"
		no-close-on-backdrop
	>
		<div class="focus_element_in_edition px-0">
			<b-card>
				<b-row>
					<b-col v-if="filterableByCountry" md="3">
						<b-form-group :label="FormMSG(122332, 'Country')" label-for="supplier-country-filter">
							<CountrySelector
								v-model="countryKeyFilter"
								custom-id="supplier-country-filter"
								@change="handleCountryChange"
								:country-selected="countryKeyFilter"
							/>
						</b-form-group>
					</b-col>
					<b-col>
						<GoogleAutoComplete
							v-model="client.name"
							style="margin-bottom: 20px"
							:label="FormMSG(33, 'Client\'s name')"
							:country="googleAutoCompleteRestrictedCountry"
							@placechanged="handleAutoCompleteChange"
						/>
					</b-col>
					<b-col v-if="isEditable" md="4">
						<b-button block class="mb-3" variant="outline-danger" @click="handleDeleteClient">
							{{ FormMSG(4, 'Delete this customer') }}
						</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group :label="FormMSG(343, 'Name')" label-for="new-client-name">
							<b-form-input id="new-client-name" v-model="client.name" type="text" required :placeholder="FormMSG(33931, 'Client\'s name')" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card>
		</div>
		<b-card>
			<b-card-title>{{ FormMSG(5, 'Data') }}</b-card-title>
			<b-card-body>
				<b-row class="supplier-data">
					<b-col>
						<b-row>
							<b-col md="6" lg="6" :class="`${$screen.width < 992 ? 'mb-3' : ''}`">
								<VatChecker v-model="client.vat" @change="handleVatChange" />
							</b-col>
							<b-col md="6" lg="6">
								<b-form-group :label="FormMSG(6, 'Company type')" label-for="supplier-input-phone">
									<b-form-select :options="companyTypes" v-model="client.companyType"></b-form-select>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row v-if="client.companyType !== 0">
							<b-col sm="6">
								<b-form-group :label="FormMSG(25, 'Contact name')" label-for="supplier-input-contact-name">
									<b-form-input
										id="supplier-input-contact-name"
										v-model="client.contactName"
										type="text"
										required
										:placeholder="FormMSG(26, 'Contact name...')"
									/>
								</b-form-group>
							</b-col>
							<b-col sm="6">
								<b-form-group :label="FormMSG(27, 'Contact function')" label-for="supplier-input-contact-function">
									<b-form-input
										id="supplier-input-contact-function"
										v-model="client.contactFunction"
										type="text"
										required
										:placeholder="FormMSG(28, 'Contact function...')"
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="6" lg="6">
								<b-form-group :label="FormMSG(10, 'Phone')" label-for="supplier-input-phone">
									<b-form-input id="supplier-input-phone" v-model="client.phone" type="text" required :placeholder="FormMSG(10, 'Phone')" />
								</b-form-group>
							</b-col>
							<b-col md="6" lg="6">
								<b-form-group :label="FormMSG(11, 'eMail')" label-for="supplier-input-email">
									<b-form-input id="supplier-input-email" v-model="client.email" type="email" required :placeholder="FormMSG(11, 'eMail')" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-card>
			<b-card-title>{{ FormMSG(12, 'Address') }}</b-card-title>
			<b-card-body>
				<AddressForm
					:auto-completed-data="handleAutoCompleteAddressFromName"
					:edit-data="client.address"
					:fill-search="searchAddressFromVat"
					:filterable-by-country="false"
					:country-key="countryKeyFilter"
					:has-country-key-parent-filter="filterableByCountry"
					@change="handleAdress"
				/>
			</b-card-body>
		</b-card>
		<b-card>
			<b-card-title>{{ FormMSG(13, 'Bank') }}</b-card-title>
			<b-card-body>
				<b-row>
					<b-col>
						<b-form-group :label="FormMSG(14, 'Iban')" label-for="supplier-input-iban">
							<b-form-input id="supplier-input-iban" v-model="client.iban" required :placeholder="FormMSG(14, 'Iban')" />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group :label="FormMSG(15, 'BIC')" label-for="supplier-input-bic">
							<b-form-input id="supplier-input-bic" v-model="client.bic" required :placeholder="FormMSG(15, 'BIC')" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-card>
			<b-card-title>{{ FormMSG(23, 'Locations') }}</b-card-title>
			<b-card-body>
				<b-row>
					<b-col md="12" lg="12">
						<client-location-list :items="client.locations" @client-location:removed="onLocationRemoved" />
					</b-col>
				</b-row>
				<b-row>
					<b-col md="6" lg="6">&nbsp;</b-col>
					<b-col md="6" lg="6">
						<b-form-group>
							<b-button type="button" variant="primary" size="md" block @click="openLocationModal">
								<span>{{ FormMSG(24, 'Add location') }}</span>
							</b-button>
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<client-location-modal
			v-model="isOpenLocationModal"
			:location="newLocation"
			@client-location-modal:cancel="onLocationCanceled"
			@client-location-modal:updated="onLocationUpdated"
		/>
	</b-modal>
</template>

<script>
import { omit, isNil, getObjectFromValue } from '@/shared/utils';
import { myProject } from '@/cruds/project.crud';

import AddressForm from '@/components/Forms/Address';
import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import VatChecker from '@/components/VatChecker';
import CountrySelector from '@/components/Selectors/CountrySelector';

import languageMessages from '@/mixins/languageMessages';
import ClientLocationList from '@/components/ClientLocation/List';
import ClientLocationModal from '@/components/ClientLocation/Modal';
import { mapActions } from 'vuex';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ClientModal',
	components: {
		CountrySelector,
		AddressForm,
		GoogleAutoComplete,
		VatChecker,
		ClientLocationList,
		ClientLocationModal
	},
	mixins: [languageMessages, globalMixin],
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		focusClient: {
			required: true,
			default: () => {}
		},
		editable: {
			type: Boolean,
			required: false,
			default: false
		},
		currentSearch: {
			type: String,
			required: false,
			default: ''
		},
		filterableByCountry: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data() {
		return {
			title: '',
			client: null,
			addressPayload: null,
			handleAutoCompleteAddressFromName: null,
			searchAddressFromVat: null,

			countryKeyFilter: 16965, // Belgium's countryKey
			googleAutoCompleteRestrictedCountry: null,
			isOpenLocationModal: false,
			newLocation: {},
			currAddress: null
		};
	},
	computed: {
		titleModal() {
			return this.FormMSG(1200, 'Edit customer');
		},
		okTitle() {
			return this.FormMSG(22, 'Save modification');
		},
		/**
		 * @return {Boolean}
		 */
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},

		/**
		 * @return {Object}
		 */
		// currAddress: {
		//   get() {
		//     const fs = this.focusClient
		//     if (isNil(fs)) return null
		//     return isNil(fs.address) ? null : fs.address
		//   },
		//   set(val) {
		//     return val
		//   }
		// },

		/**
		 * @return {Boolean}
		 */
		isEditable() {
			return this.editable;
		},

		rendConfirmModalTxt() {
			const _t = this.FormMSG;
			return {
				title: _t(56561, 'Please Confirm'),
				okTitle: _t(56562, 'YES'),
				cancelTitle: _t(56563, 'NO')
			};
		},

		optionsForCountryCode() {
			return this.FormMenu(9);
		},
		companyTypes() {
			return this.FormMenu(1325);
		}
	},
	watch: {
		focusClient: {
			/**
			 * @param {Object}
			 */
			handler(client) {
				if (!isNil(client)) {
					this.initCurrSearch();
					this.initCountrySearch();
					this.initDefaultRestrictedCountry();
					this.initEdit(client);
					// this.currAddress = client.address;
					// console.log('client: ', client);
				}
			},
			deep: true,
			immediate: true
		},

		/**
		 * @param {String} name
		 */
		'supplier.name'(name) {
			this.handleSupplierNameChange(name);
		},

		optionsForCountryCode: {
			handler() {
				this.getCountryIsoFromKey(this.countryKeyFilter);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.initCurrSearch();
			this.initCountrySearch();
			this.initDefaultRestrictedCountry();
			this.initEdit(this.focusClient);
		});
		// console.log('client: ', this.client);
	},
	methods: {
		initCurrSearch() {
			if (this.currentSearch === '') return;
			this.client.name = this.currentSearch;
		},

		async initCountrySearch() {
			const project = await myProject();
			if (project.addressId === 0) return;
			const countryKey = project.address.countryKey;
			this.countryKeyFilter = countryKey === 0 ? 16965 : countryKey;
		},

		initDefaultRestrictedCountry() {
			if (!this.filterableByCountry) return;
			const key = this.countryKeyFilter;
			this.googleAutoCompleteRestrictedCountry = this.getCountryIsoFromKey(key);
		},

		/**
		 * @param {Object} supplier
		 */
		initEdit(supplier) {
			this.client = supplier; // console.log('supplier: ', supplier)
		},

		handleCloseModal() {
			this.$emit('input', false);
		},

		handleDeleteClient() {
			if (!this.isEditable) return;

			const txt = this.FormMSG(20, 'Please confirm that you want to delete this customer');
			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((v) => {
					if (!v) return;
					this.$emit('client:delete', this.client);
				})
				.catch((err) => {
					this.createToastForMobile(this.FormMSG(55, 'Error'), err.message, '', 'danger');
				});
		},

		/**
		 * @param {String} name
		 */
		handleSupplierNameChange(name) {
			this.$emit('supplier-name-change', name);
		},

		/**
		 * @param {Object} address
		 */
		handleAdress(address) {
			this.addressPayload = address;
			this.client.address = address;
		},
		handleSubmitModal() {
			if (this.editable) {
				// If update
				this.$emit('client:update', {
					client: this.client,
					address: this.addressPayload
				});
			} else {
				// If create
				this.$emit('client:create', {
					client: this.client,
					address: this.addressPayload
				});
			}
		},

		/**
		 * @param {Object} value
		 */
		handleAutoCompleteChange(value) {
			this.client.name = value.name;
			this.handleAutoCompleteAddressFromName = {
				...this.addressPayload,
				street: value.route,
				city: value.locality,
				number: value.street_number,
				state: value.administrative_area_level_1,
				zip: value.postal_code,
				country: value.country,
				lat: value.latitude,
				lng: value.longitude
			};
		},

		/**
		 * @param {Object} vat
		 */
		handleVatChange(vat) {
			this.client.vat = vat.originVat;
			this.client.name = vat.name;
			if (isNil(vat.address)) return;
			this.searchAddressFromVat = vat.address;
		},

		/**
		 * @param {Object} country
		 */
		handleCountryChange(country) {
			this.googleAutoCompleteRestrictedCountry = !isNil(country) ? country.code.toLowerCase() : '';
		},

		/**
		 * @param {Number} key
		 */
		getCountryIsoFromKey(key = 16965) {
			const country = getObjectFromValue(this.optionsForCountryCode, 'value', key);
			return !isNil(country) ? country.text.toLowerCase() : '';
		},
		...mapActions({
			setNewLocation: 'locations/setNewLocation'
		}),
		async openLocationModal() {
			await this.setNewLocation(parseInt(this.client.id), 10);
			this.newLocation = this.$store.getters['locations/currLocation'];
			if (isNil(this.newLocation)) return;
			if (this.client.locations === undefined) {
				this.$set(this.client, 'locations', []);
			}
			this.client.locations.push(this.newLocation);
			this.isOpenLocationModal = true;
			this.$emit('client-location:updated', this.newLocation);
		},
		onLocationCanceled() {
			this.isOpenLocationModal = false;
		},
		onLocationUpdated(payload) {
			this.$emit('client-location:updated', payload);
		},
		onLocationRemoved(payload) {
			this.$emit('client-location:removed', payload);
		}
	}
};
</script>
