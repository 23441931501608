<template>
	<div role="group" class="select_items_filter_wrapper">
		<label v-if="withLabel" v-html="label" class="d-block" />
		<b-row>
			<b-col
				:md="`${canDoAdd ? (locations.length > 0 ? 4 : 8) : locations.length > 0 ? 6 : 12}`"
				:lg="`${canDoAdd ? (locations.length > 0 ? 4 : 8) : locations.length > 0 ? 6 : 12}`"
			>
				<v-select
					v-model="selected_client"
					:placeholder="placeholder"
					:options="clients"
					@option:selected="emitSelectedClient"
					@input="onInput"
					class="mb-2"
					:style="`${!isValid ? 'border: 1px solid #B11548; border-radius: 4px;' : ''}`"
					:disabled="disabled"
					label="name"
				>
					<template v-slot:no-options="{ search, searching }">
						<template v-if="searching">
							{{ FormMSG(1, 'No results for') }} <em>{{ search }}</em
							>. {{ FormMSG(2, 'You can add it') }}.
						</template>
						<em style="opacity: 0.5" v-else>{{ FormMSG(3, 'Start typing to search for a supplier') }}.</em>
					</template>
				</v-select>
			</b-col>
			<b-col :md="`${canDoAdd ? 4 : 6}`" :lg="`${canDoAdd ? 4 : 6}`" v-if="locations.length > 0">
				<v-select
					v-model="locationSelected"
					:placeholder="locationPlaceholder"
					:options="locations"
					@option:selected="onSelectedLocation"
					@input="onDeleteLocation"
					:disabled="disabled"
					label="setName"
					:class="$screen.width < 992 ? 'mb-2' : ''"
				/>
			</b-col>
			<b-col md="4" lg="4" v-if="canDoAdd">
				<div class="supplier_actions_btns" :class="{ has_two_btn: isCanModify }">
					<b-button v-if="isCanModify" @click="openModalEdit" class="py-2">
						<i class="fa fa-edit" />
					</b-button>
					<b-button
						v-html="`${!isCanModify ? addLabel : updateLabel}`"
						block
						variant="outline-primary"
						@click="`${!isCanModify ? openModalNew() : openModalEdit()}`"
						class="py-2"
					/>
				</div>
			</b-col>
		</b-row>
		<ClientModal
			v-model="open_modal"
			:editable="is_modal_edit"
			:focus-client="data_to_edit"
			@client:delete="handleDeleteClient"
			@client:create="createClient"
			@client:update="editClient"
			@client-location:removed="onLocationRemoved"
		/>
	</div>
</template>

<script>
// import { mapCruds } from "crudx";
import { isNil } from '~utils';

import { getClients, deleteClient, addClient, updateClient } from '@/cruds/clients.crud';

import languageMessages from '@/mixins/languageMessages';

import ClientModal from './ClientModal';
import globalMixin from '@/mixins/global.mixin';
// import { store } from "@/store";

export default {
	name: 'CustomerSelector',
	mixins: [languageMessages, globalMixin],
	props: {
		placeholder: {
			type: String,
			default: 'Choose customer ...'
		},
		label: {
			type: String,
			default: 'Customer name'
		},
		addLabel: {
			type: String,
			default: 'Add'
		},
		updateLabel: {
			type: String,
			default: 'Update'
		},
		withLabel: {
			type: Boolean,
			required: false,
			default: false
		},
		isValid: {
			type: Boolean,
			required: false,
			default: true
		},
		clientId: {
			type: [String, Number],
			required: false,
			default: 0
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		canDoAdd: {
			type: Boolean,
			required: false,
			default: true
		},
		locationPlaceholder: {
			type: String,
			default: 'Choose location ...'
		},
		locationId: {
			type: [String, Number],
			required: false,
			default: 0
		}
	},
	components: {
		ClientModal
	},

	data() {
		return {
			clients: [],
			selected_client: null,
			isCanModify: false,
			open_modal: false,
			is_modal_edit: false,
			data_to_edit: {},
			locationSelected: null,
			locations: []
		};
	},

	methods: {
		/**
		 * For init clients list
		 * @returns {Promise<void>}
		 */
		async initClients() {
			this.clients = (await getClients()).clients;
		},

		/**
		 * Open modal for new customer
		 */
		openModalNew() {
			this.is_modal_edit = false;
			this.data_to_edit = {};
			this.$set(this.data_to_edit, 'companyType', 0);
			this.open_modal = true;
		},

		/**
		 * Open modal for edit customer
		 */
		openModalEdit() {
			this.open_modal = true;
			this.is_modal_edit = true;
		},

		/**
		 * For delete client
		 * @param payload
		 * @returns {Promise<void>}
		 */
		async handleDeleteClient(payload) {
			this.open_modal = false;
			await deleteClient(payload.id, payload.addressId);
			this.clients = this.clients.filter((c) => parseInt(c.id, 10) !== payload.id);
			this.selected_client = null;
			this.locationSelected = null;
			this.$emit('client:selected', null);
			this.isCanModify = false;

			this.createToastForMobile(this.FormMSG(56, 'Success'), this.FormMSG(53, 'Delete successfull'), '', 'success');
		},

		/**
		 * For createClient
		 * @param payload
		 * @returns {Promise<void>}
		 */
		async createClient(payload) {
			let add_client = null;
			if (!isNil(payload.client.name) && payload.client.name !== '' && !isNil(payload.address)) {
				add_client = await addClient(payload.client.name);
			} else {
				this.createToastForMobile(this.FormMSG(78, 'Error'), this.FormMSG(77, 'Name and address are required'), '', 'danger');
				return;
			}
			// console.log("CLIENT FOR CREATE",payload.client);
			const update_client = await updateClient({
				id: add_client.id,
				client: payload.client,
				address: payload.address
			});
			// const data = {
			//   label: update_client.name,
			//   id: parseInt(add_client.id)
			// }
			this.selected_client = update_client;
			this.emitSelectedClient(update_client);
			this.clients.push(this.selected_client);
			this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(52, 'Create client successfull'), '', 'success');
		},

		/**
		 * For update client
		 * @param payload
		 * @returns {Promise<void>}
		 */
		async editClient(payload) {
			let client = null;
			// console.log('editClient: ', payload);
			client = await updateClient({
				id: payload.client.id,
				client: payload.client,
				address: payload.address
			});
			// console.log('editClientSave: ', payload);
			client.address = payload.address;
			this.selected_client = client;
			const findIndex = this.clients.findIndex((c) => parseInt(c.id, 10) === parseInt(payload.client.id, 10));
			// console.log({findIndex})
			this.clients[findIndex] = client;

			this.createToastForMobile(this.FormMSG(56, 'Success'), this.FormMSG(55, 'Update client successfull'), '', 'success');
		},

		/**
		 * To send the client select
		 * Format of payload { id: Int, label: String }
		 * @param payload {Object}
		 */
		emitSelectedClient(payload) {
			this.$emit('client:selected', payload);
			this.isCanModify = true;
			this.data_to_edit = payload;
			this.locationSelected = null;
			this.locations = payload.locations;
		},

		/**
		 * Event that listens to the input of the select
		 * @param payload
		 * @returns {Promise<void>}
		 */
		async onInput(payload) {
			if (isNil(payload)) {
				this.isCanModify = false;
				// this.data_to_edit = {};
				this.locations = [];
				this.$emit('client:selected', null);
			}
		},
		onSelectedLocation(payload) {
			// console.log('onSelectedLocation: ', payload)
			this.$emit('client-location:selected', payload);
		},
		onDeleteLocation(payload) {
			// console.log('onDeleteLocation: ', payload)
			this.$emit('client-location:selected', payload);
		},
		onLocationRemoved(payload) {
			if (payload === this.locationSelected.id) {
				this.locationSelected = null;
			}
			this.canDoAdd = true;
			this.$emit('client-location:removed', payload);
		}
	},
	watch: {
		clientId: {
			handler: async function (n) {
				await this.initClients();
				if (!isNil(n) && n > 0) {
					const clientSelected = this.clients.filter((client) => parseInt(client.id, 10) === n);
					if (clientSelected.length > 0) {
						this.selected_client = clientSelected[0];
						this.isCanModify = true;
						this.data_to_edit = this.selected_client;
						this.locations = this.selected_client.locations;
						if (!isNil(this.locationId) && this.locationId > 0) {
							const locationSelected = this.locations.filter((location) => parseInt(location.id, 10) === this.locationId);
							if (locationSelected.length > 0) {
								this.locationSelected = locationSelected[0];
							}
						}
					}
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
