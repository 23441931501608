var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.titleModal,
        size: "xl",
        "ok-variant": "success",
        "ok-title": _vm.okTitle,
        "cancel-title": _vm.FormMSG(2, "Cancel"),
        "no-close-on-backdrop": "",
      },
      on: {
        ok: _vm.handleSubmitModal,
        cancel: _vm.handleCloseModal,
        hidden: _vm.handleCloseModal,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "focus_element_in_edition px-0" },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _vm.filterableByCountry
                    ? _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(122332, "Country"),
                                "label-for": "supplier-country-filter",
                              },
                            },
                            [
                              _c("CountrySelector", {
                                attrs: {
                                  "custom-id": "supplier-country-filter",
                                  "country-selected": _vm.countryKeyFilter,
                                },
                                on: { change: _vm.handleCountryChange },
                                model: {
                                  value: _vm.countryKeyFilter,
                                  callback: function ($$v) {
                                    _vm.countryKeyFilter = $$v
                                  },
                                  expression: "countryKeyFilter",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    [
                      _c("GoogleAutoComplete", {
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: {
                          label: _vm.FormMSG(33, "Client's name"),
                          country: _vm.googleAutoCompleteRestrictedCountry,
                        },
                        on: { placechanged: _vm.handleAutoCompleteChange },
                        model: {
                          value: _vm.client.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.client, "name", $$v)
                          },
                          expression: "client.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.isEditable
                    ? _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-3",
                              attrs: { block: "", variant: "outline-danger" },
                              on: { click: _vm.handleDeleteClient },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(4, "Delete this customer")
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(343, "Name"),
                            "label-for": "new-client-name",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "new-client-name",
                              type: "text",
                              required: "",
                              placeholder: _vm.FormMSG(33931, "Client's name"),
                            },
                            model: {
                              value: _vm.client.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.client, "name", $$v)
                              },
                              expression: "client.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-title", [_vm._v(_vm._s(_vm.FormMSG(5, "Data")))]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                { staticClass: "supplier-data" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              class: `${_vm.$screen.width < 992 ? "mb-3" : ""}`,
                              attrs: { md: "6", lg: "6" },
                            },
                            [
                              _c("VatChecker", {
                                on: { change: _vm.handleVatChange },
                                model: {
                                  value: _vm.client.vat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.client, "vat", $$v)
                                  },
                                  expression: "client.vat",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(6, "Company type"),
                                    "label-for": "supplier-input-phone",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.companyTypes },
                                    model: {
                                      value: _vm.client.companyType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.client, "companyType", $$v)
                                      },
                                      expression: "client.companyType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.client.companyType !== 0
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { sm: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(25, "Contact name"),
                                        "label-for":
                                          "supplier-input-contact-name",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "supplier-input-contact-name",
                                          type: "text",
                                          required: "",
                                          placeholder: _vm.FormMSG(
                                            26,
                                            "Contact name..."
                                          ),
                                        },
                                        model: {
                                          value: _vm.client.contactName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.client,
                                              "contactName",
                                              $$v
                                            )
                                          },
                                          expression: "client.contactName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { sm: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          27,
                                          "Contact function"
                                        ),
                                        "label-for":
                                          "supplier-input-contact-function",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "supplier-input-contact-function",
                                          type: "text",
                                          required: "",
                                          placeholder: _vm.FormMSG(
                                            28,
                                            "Contact function..."
                                          ),
                                        },
                                        model: {
                                          value: _vm.client.contactFunction,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.client,
                                              "contactFunction",
                                              $$v
                                            )
                                          },
                                          expression: "client.contactFunction",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(10, "Phone"),
                                    "label-for": "supplier-input-phone",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "supplier-input-phone",
                                      type: "text",
                                      required: "",
                                      placeholder: _vm.FormMSG(10, "Phone"),
                                    },
                                    model: {
                                      value: _vm.client.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.client, "phone", $$v)
                                      },
                                      expression: "client.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "6", lg: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(11, "eMail"),
                                    "label-for": "supplier-input-email",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "supplier-input-email",
                                      type: "email",
                                      required: "",
                                      placeholder: _vm.FormMSG(11, "eMail"),
                                    },
                                    model: {
                                      value: _vm.client.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.client, "email", $$v)
                                      },
                                      expression: "client.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-title", [_vm._v(_vm._s(_vm.FormMSG(12, "Address")))]),
          _c(
            "b-card-body",
            [
              _c("AddressForm", {
                attrs: {
                  "auto-completed-data": _vm.handleAutoCompleteAddressFromName,
                  "edit-data": _vm.client.address,
                  "fill-search": _vm.searchAddressFromVat,
                  "filterable-by-country": false,
                  "country-key": _vm.countryKeyFilter,
                  "has-country-key-parent-filter": _vm.filterableByCountry,
                },
                on: { change: _vm.handleAdress },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-title", [_vm._v(_vm._s(_vm.FormMSG(13, "Bank")))]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(14, "Iban"),
                            "label-for": "supplier-input-iban",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "supplier-input-iban",
                              required: "",
                              placeholder: _vm.FormMSG(14, "Iban"),
                            },
                            model: {
                              value: _vm.client.iban,
                              callback: function ($$v) {
                                _vm.$set(_vm.client, "iban", $$v)
                              },
                              expression: "client.iban",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(15, "BIC"),
                            "label-for": "supplier-input-bic",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "supplier-input-bic",
                              required: "",
                              placeholder: _vm.FormMSG(15, "BIC"),
                            },
                            model: {
                              value: _vm.client.bic,
                              callback: function ($$v) {
                                _vm.$set(_vm.client, "bic", $$v)
                              },
                              expression: "client.bic",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-title", [_vm._v(_vm._s(_vm.FormMSG(23, "Locations")))]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12", lg: "12" } },
                    [
                      _c("client-location-list", {
                        attrs: { items: _vm.client.locations },
                        on: {
                          "client-location:removed": _vm.onLocationRemoved,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { md: "6", lg: "6" } }, [_vm._v(" ")]),
                  _c(
                    "b-col",
                    { attrs: { md: "6", lg: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "button",
                                variant: "primary",
                                size: "md",
                                block: "",
                              },
                              on: { click: _vm.openLocationModal },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.FormMSG(24, "Add location"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("client-location-modal", {
        attrs: { location: _vm.newLocation },
        on: {
          "client-location-modal:cancel": _vm.onLocationCanceled,
          "client-location-modal:updated": _vm.onLocationUpdated,
        },
        model: {
          value: _vm.isOpenLocationModal,
          callback: function ($$v) {
            _vm.isOpenLocationModal = $$v
          },
          expression: "isOpenLocationModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }